<template>
  <div class="rightLink">
    <div class="bottomDiv" style="height: 100%;">
      <div class="title">自定义峰段</div>
      <div style="margin-top:20px" class="formDiv">
        <div class="custom-block">
          <p>价格设置</p>
        </div>
        <el-form ref="ruleFormRef" :rules="rules" :model="formData" label-position="right" label-width="120px">
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="模板名称:" prop="price_name">
                <el-input v-model="formData.price_name" placeholder="请输入模板名称" required></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" v-if="isAdmin">
              <el-form-item label="运营商:" prop="username">
                <el-select v-model="formData.username" placeholder="请选择运营商" style="width: 100%;">
                  <el-option v-for="username in users" :key="username.id" :value="username.username"
                    :label="username.username" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11" v-if="!isAdmin" prop="username">
              <el-form-item label="运营商:">
                <el-input v-model="formData.username" placeholder="请输入所属运营商"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="尖峰电费(元):" prop="j_electric">
                <el-input v-model="formData.j_electric" placeholder="请输入尖峰电费"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="尖峰服务费(元):" prop="j_service">
                <el-input v-model="formData.j_service" placeholder="请输入尖峰服务费"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="高峰电费(元):" prop="g_electric">
                <el-input v-model="formData.g_electric" placeholder="请输入高峰电费"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="高峰服务费(元):" prop="g_service">
                <el-input v-model="formData.g_service" placeholder="请输入高峰服务费"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="平峰电费(元):" prop="p_electric">
                <el-input v-model="formData.p_electric" placeholder="请输入平峰电费"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="平峰服务费(元):" prop="p_service">
                <el-input v-model="formData.p_service" placeholder="请输入平峰服务费"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="谷峰电费(元):" prop="d_electric">
                <el-input v-model="formData.d_electric" placeholder="请输入谷峰服务费"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="谷峰服务费(元):" prop="d_service">
                <el-input v-model="formData.d_service" placeholder="请输入谷峰服务费"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="custom-block">
            <p>峰段设置（最多可设置48个时段）</p>
          </div>

          <el-row>
            <el-col :span="12" v-for="item in loopList" :key="item" style="margin-bottom: 10px;">
              <div class="flex">
                <el-select v-model="item.startDate" placeholder="请选择开始时间" style="width: 150px">
                  <el-option v-for="item in hourOptionList" :key="item.value" :label="item.label" :value="item.value"
                    :disabled="item.disabled" />
                </el-select>
                <span style="padding: 0 8px">至</span>
                <el-select v-model="item.endDate" placeholder="请选择结束时间" style="width: 150px">
                  <el-option v-for="item in hourOptionList" :key="item.value" :label="item.label" :value="item.value"
                    :disabled="item.disabled" />
                </el-select>
                <el-select v-model="item.interval" style="width: 100px; margin-left: 10px">
                  <el-option v-for="item in intervalOptionList" :key="item.value" :label="item.label"
                    :value="item.value" />
                </el-select>
              </div>
            </el-col>

            <el-col :span="12">
              <div class="flex">
                <el-input placeholder="剩余时间段" style="width: 150px" disabled></el-input>
                <el-select v-model="restInterval" style="width: 100px; margin-left: 10px">
                  <el-option v-for="item in intervalOptionList" :key="item.value" :label="item.label"
                    :value="item.value" />
                </el-select>
              </div>
            </el-col>
          </el-row>

          <el-button @click="addTimeInterval" style="margin-top: 15px">
            <el-icon>
              <Plus />
            </el-icon>
            添加时段
          </el-button>
        </el-form>
        <div class="btnDiv" style="width: 100%;">
          <el-button style="margin: 0 auto;" @click="handleSubmit(ruleFormRef)">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs, ref } from "vue-demi";
import { billing_models, operator_data } from "@/request/api";
import { ElMessage } from "element-plus";
import moment from 'moment'

export default {
  name: "FormantManage",
  setup() {
    onMounted(() => {
      getUserName()
    })

    const ruleFormRef = ref()
    const basicObj = {
      'time_frame0': '',
      'time_frame03': '',
      'time_frame1': '',
      'time_frame013': '',
      'time_frame2': '',
      'time_frame023': '',
      'time_frame3': '',
      'time_frame33': '',
      'time_frame4': '',
      'time_frame43': '',
      'time_frame5': '',
      'time_frame53': '',
      'time_frame6': '',
      'time_frame63': '',
      'time_frame7': '',
      'time_frame73': '',
      'time_frame8': '',
      'time_frame83': '',
      'time_frame9': '',
      'time_frame93': '',
      'time_frame10': '',
      'time_frame103': '',
      'time_frame11': '',
      'time_frame113': '',
      'time_frame12': '',
      'time_frame123': '',
      'time_frame13': '',
      'time_frame133': '',
      'time_frame14': '',
      'time_frame143': '',
      'time_frame15': '',
      'time_frame153': '',
      'time_frame16': '',
      'time_frame163': '',
      'time_frame17': '',
      'time_frame173': '',
      'time_frame18': '',
      'time_frame183': '',
      'time_frame19': '',
      'time_frame193': '',
      'time_frame20': '',
      'time_frame203': '',
      'time_frame21': '',
      'time_frame213': '',
      'time_frame22': '',
      'time_frame223': '',
      'time_frame23': '',
      'time_frame233': '',
    }
    const directory = {
      0: '0', // 00:00 - 00:30
      1: '03', // 00:30 - 01:00
      2: '1', // 01:00 - 01:30
      3: '013', // 01:30 - 02:00
      4: '2', // 02:00 - 02:30
      5: '023', // 02:30 - 03:00
      6: '3', // 03:00 - 03:30
      7: '33', // 03:30 - 04:00
      8: '4', // 04:00 - 04:30
      9: '43', // 04:30 - 05:00
      10: '5', // 05:00 - 05:30
      11: '53', // 05:30 - 06:00
      12: '6', // 06:00 - 06:30
      13: '63', // 06:30 - 07:00
      14: '7', // 07:00 - 07:30
      15: '73', // 07:30 - 08:00
      16: '8', // 08:00 - 08:30
      17: '83', // 08:30 - 09:00
      18: '9', // 09:00 - 09:30
      19: '93', // 09:30 - 10:00
      20: '10', // 10:00 - 10:30
      21: '103', // 10:30 - 11:00
      22: '11', // 11:00 - 11:30
      23: '113', // 11:30 - 12:00
      24: '12', // 12:00 - 12:30
      25: '123', // 12:30 - 13:00
      26: '13', // 13:00 - 13:30
      27: '133', // 13:30 - 14:00
      28: '14', // 14:00 - 14:30
      29: '143', // 14:30 - 15:00
      30: '15', // 15:00 - 15:30
      31: '153', // 15:30 - 16:00
      32: '16', // 16:00 - 16:30
      33: '163', // 16:30 - 17:00
      34: '17', // 17:00 - 17:30
      35: '173', // 17:30 - 18:00
      36: '18', // 18:00 - 18:30
      37: '183', // 18:30 - 19:00
      38: '19', // 19:00 - 19:30
      39: '193', // 19:30 - 20:00
      40: '20', // 20:00 - 20:30
      41: '203', // 20:30 - 21:00
      42: '21', // 21:00 - 21:30
      43: '213', // 21:30 - 22:00
      44: '22', // 22:00 - 22:30
      45: '223', // 22:30 - 23:00
      46: '23', // 23:00 - 23:30
      47: '233', // 23:30 - 24:00
    }
    const intervalOptionList = [
      { value: '00', label: '尖峰' },
      { value: '01', label: '高峰' },
      { value: '02', label: '平峰' },
      { value: '03', label: '谷峰' },
    ]
    const hourOptionList = ref([
      { value: 0, label: '00:00' },
      { value: 1, label: '00:30' },
      { value: 2, label: '01:00' },
      { value: 3, label: '01:30' },
      { value: 4, label: '02:00' },
      { value: 5, label: '02:30' },
      { value: 6, label: '03:00' },
      { value: 7, label: '03:30' },
      { value: 8, label: '04:00' },
      { value: 9, label: '04:30' },
      { value: 10, label: '05:00' },
      { value: 11, label: '05:30' },
      { value: 12, label: '06:00' },
      { value: 13, label: '06:30' },
      { value: 14, label: '07:00' },
      { value: 15, label: '07:30' },
      { value: 16, label: '08:00' },
      { value: 17, label: '08:30' },
      { value: 18, label: '09:00' },
      { value: 19, label: '09:30' },
      { value: 20, label: '10:00' },
      { value: 21, label: '10:30' },
      { value: 22, label: '11:00' },
      { value: 23, label: '11:30' },
      { value: 24, label: '12:00' },
      { value: 25, label: '12:30' },
      { value: 26, label: '13:00' },
      { value: 27, label: '13:30' },
      { value: 28, label: '14:00' },
      { value: 29, label: '14:30' },
      { value: 30, label: '15:00' },
      { value: 31, label: '15:30' },
      { value: 32, label: '16:00' },
      { value: 33, label: '16:30' },
      { value: 34, label: '17:00' },
      { value: 35, label: '17:30' },
      { value: 36, label: '18:00' },
      { value: 37, label: '18:30' },
      { value: 38, label: '19:00' },
      { value: 39, label: '19:30' },
      { value: 40, label: '20:00' },
      { value: 41, label: '20:30' },
      { value: 42, label: '21:00' },
      { value: 43, label: '21:30' },
      { value: 44, label: '22:00' },
      { value: 45, label: '22:30' },
      { value: 46, label: '23:00' },
      { value: 47, label: '23:30' },
      { value: 48, label: '24:00' },
    ])

    const loopList = ref([
      {
        startDate: '',
        endDate: '',
        interval: ''
      },
    ])

    const data = reactive({
      formData: {},
      rules: {
        price_name: [
          { required: true, message: '请输入模板名称', trigger: 'blur' },
        ],
        username: [
          { required: true, message: '请选择运营商' },
        ],
        j_electric: [
          { required: true, message: '请输入尖峰电费', trigger: 'blur' },
        ],
        j_service: [
          { required: true, message: '请输入尖峰服务费', trigger: 'blur' },
        ],
        g_electric: [
          { required: true, message: '请输入高峰电费', trigger: 'blur' },
        ],
        g_service: [
          { required: true, message: '请输入高峰服务费', trigger: 'blur' },
        ],
        p_electric: [
          { required: true, message: '请输入平峰电费', trigger: 'blur' },
        ],
        p_service: [
          { required: true, message: '请输入平峰服务费', trigger: 'blur' },
        ],
        d_electric: [
          { required: true, message: '请输入谷峰电费', trigger: 'blur' },
        ],
        d_service: [
          { required: true, message: '请输入谷峰服务费', trigger: 'blur' },
        ],
      },
      isAdmin: false,
      users: [],
      intervalObj: {},
      restInterval: ''
    });

    const getUserName = () => {
      const username = localStorage.getItem('username')
      const userList = []
      operator_data().then((res) => {
        data.users = res
        res.forEach((a) => {
          userList.push(a.username)
        })
        if (userList.indexOf(username) == -1) {
          data.isAdmin = true
        } else {
          data.isAdmin = false
          data.formData.username = username
        }
      })
    }

    const handleSubmit = async (formEl) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          const list = JSON.parse(JSON.stringify(loopList.value))
          transferList2Obj(list)

          if (!isDateValid()) return false
          if (!isIntervalObjComplete()) return false

          console.log('formData:', JSON.parse(JSON.stringify(data.formData)))
          const finalData = Object.assign({}, data.formData, data.intervalObj)
          console.log('finalData:', finalData)

          billing_models(finalData).then((res) => {
            if (res) {
              if (res.code == 200) {
                ElMessage({
                  showClose: true,
                  message: "新增成功",
                  type: "success",
                });
                data.formData = {}
              } else {
                ElMessage.error(res.msg)
              }
            } else {
              ElMessage.error("提交失败");
            }
          })
        } else {
          console.log('error submit!', fields)
        }
      })
    }

    const isIntervalObjComplete = () => {
      let isComplete = true
      for (let key in data.intervalObj) {
        if (data.intervalObj[key] == '') {
          isComplete = false
        }
      }
      if (!isComplete) {
        ElMessage({ message: '峰段设置不完整', type: 'warning' })
        return false
      }
      return isComplete
    }

    const isDateValid = () => {
      let isValid = true
      loopList.value.forEach(item => {
        if (item.startDate === '' || item.endDate === '') {
          ElMessage({ message: '开始时间和结束时间不能为空', type: 'warning' })
          isValid = false
        }
        if (moment(item.startDate) >= moment(item.endDate)) {
          ElMessage({ message: '开始时间应早于结束时间', type: 'warning' })
          isValid = false
        }
      })
      return isValid
    }

    const addTimeInterval = () => {
      if (!isDateValid()) return false
      // 上一个选中的时间段在下一个里不能再选了
      loopList.value.forEach(item => {
        if (item.startDate !== '' && item.endDate !== '' && item.startDate < item.endDate) {
          for (let i = item.startDate; i < item.endDate; i++) {
            hourOptionList.value[i].disabled = true
          }
        }
      })
      loopList.value.push({
        startDate: '',
        endDate: '',
        interval: ''
      })
    }

    const transferList2Obj = (list) => {
      // console.log('list', list)
      data.intervalObj = {} // 先置空
      list.forEach(item => {
        // console.log('item', item)
        for (let i = item.startDate; i < item.endDate; i++) {
          data.intervalObj['time_frame' + directory[i]] = item.interval
        }
      })
      let keyList = Object.keys(data.intervalObj)
      // console.log('keyList', keyList)
      for (let key in basicObj) {
        let index = keyList.findIndex(item => item === key)
        if (index == -1) { // 剩余时间段
          data.intervalObj[key] = data.restInterval
        }
      }
      console.log('intervalObj:', JSON.parse(JSON.stringify(data.intervalObj)))
    }

    return {
      ruleFormRef,
      basicObj,
      directory,
      loopList,
      intervalOptionList,
      hourOptionList,
      ...toRefs(data),
      handleSubmit,
      getUserName,
      addTimeInterval,
      transferList2Obj
    };
  },
};
</script>

<style scoped>
.custom-block {
  margin: 15px 0;
  padding: 11px;
  border-radius: 5px;
  border-left: 5px solid rgb(66, 167, 169);
  font-size: 15px;
  color: rgb(66, 167, 169);
  background-color: #E3F9F9;
}
</style>